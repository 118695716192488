<template>
  <div
    class="bg"
    style="height: 80vh"
  >
    <div class="box">
      <div class="listBox">
        <div
          class="mb10"
          style="display: flex;"
        >
          <div class="Ttext mb10">是否开启</div>
          <div style="width: 320px;margin-left: 30px;">
            <el-switch
              v-model="value"
              active-color="#025ee9"
              inactive-color="#909399"
            > </el-switch>
          </div>
        </div>
        <div
          class="mb10"
          style="display: flex;margin-top: 30px;"
        >
          <div class="Ttext mb10">欢迎方式</div>
          <div style="width: 320px;margin-left: 30px;">
            <el-radio
              v-model="radio"
              :label="1"
            >语音助播</el-radio>
            <!-- <el-radio v-model="radio" :label="2">文字</el-radio> -->
          </div>
        </div>
        <div
          class="mb10"
          style="margin-top: 30px;"
        >
          <div style="display: flex;position: relative;width: 100%;">
            <div
              class="Ttext mb10"
              style="width: 90%;"
            >触发关键词</div>
            <div><el-button
                class="add_tag"
                icon="el-icon-circle-plus-outline"
                @click="addKey"
              >添加回复语句</el-button></div>
          </div>

          <div style="display: flex;flex-wrap: wrap;">
            <div
              v-for="(i, idx) in keywordList"
              :key="idx"
              class="df mb10"
              style="width: 30%;margin-left: 30px;"
            >
              <el-input
                v-model="keywordList[idx]"
                placeholder="请输入"
              ></el-input>
              <el-button
                style="margin-left: 20px;background: #000000;border-style: none;"
                type="info"
                icon="el-icon-delete"
                @click="delKey(idx)"
              ></el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { edit_gift } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    giftInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    liveId: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      radio: "1",
      value: true,
      keywordList: [""],
      visible: false,
      ipt: "",
      isPlay: false,
      videoList: [],
    };
  },
  watch: {
    giftInfo (nval) {
      if (nval != "") {
        console.log(nval);
        this.keywordList = [];
        this.value = nval.stutas == 1 ? true : false;
        this.radio = nval.types;
        this.keywordList.push(...nval.texts);
      }
    },
  },
  mounted () {
  },
  methods: {
    back () {
      this.$emit("back");
    },
    addKey () {
      this.keywordList.push("");
    },
    delKey (idx) {
      this.keywordList.splice(idx, 1);
    },
    save () {
      console.log(111);
      edit_gift({
        live_id: this.liveId,
        stutas: this.value ? 1 : 0,
        types: this.radio,
        texts: this.keywordList
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.Ttext {
  color: #fff;
  font-size: 14px;
  // font-weight: 700;
}
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #202532;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  /deep/ .el-input__inner {
    background: #0f1319;
  }
}
.add_tag {
  width: 158px;
  height: 42px;
  opacity: 0.8;
  border-radius: 10px;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff00;
  color: #ffffff;
}
</style>
