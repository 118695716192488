<template>
  <div
    class="bg"
    style="height: 92vh"
  >
    <el-container>
      <el-header
        class="bg-white"
        style="line-height: 60px"
      >
        <!-- <div class="dfc">
          <div class="df">
            <div
              class="back"
              @click="back"
            >
              <img
                src="~@/assets/img/back1.png"
                alt=""
              />
            </div>
            <div class="twoMenubox">
              <div
                v-for="(i, idx) in menu"
                :key="idx"
                class="menu"
                :class="current == idx ? 'active' : ''"
                @click="menuHandle(i, idx)"
              >
                <img
                  :src="current == idx ? i.icon : i.activeicon"
                  alt=""
                />
                <div>{{ i.title }}</div>
              </div>
            </div>
          </div>

          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="showAdd = true"
          >添加</el-button>
        </div> -->
        <div style="padding: 0.625rem 1.25rem;display: flex;">
          <div style="width: 80%;">
            <el-tabs
              v-model="activeNames"
              @tab-click="saveClick"
            >
              <el-tab-pane
                label="商品讲解"
                name="list"
              ></el-tab-pane>
            </el-tabs>
          </div>

          <div style="width: 30%;">
            <div class="df jc-sb bg-white head">
              <el-button
                class="rignt_label"
                @click="back"
              >返回</el-button>
              <el-button
                icon="el-icon-delete"
                class="rignt_label"
                @click="showAdd = true"
              >添加</el-button>
            </div>

          </div>
        </div>
      </el-header>
      <el-main>
        <div class="box">
          <div class="vlist">
            <div
              v-if="videoList.length > 0"
              class="mt10"
            >
              <div style="">
                <div
                  v-for="(i, idx) in videoList"
                  :key="idx"
                  class="listBox item"
                  @click="activeInfo = i"
                >
                  <img
                    src="~@/assets/img/shop.png"
                    alt=""
                  />
                  <div class="info">
                    <div class="tit"><span class="Ttext">讲解名称：
                        <span style="color: #fff;">{{ i.goods_name }}</span>
                      </span>
                    </div>
                    <div class="mt5"><span class="Ttext">讲解视频数量：
                        <span
                          style="color: #fff;">{{ i.videvo_ids_num }}个</span>
                      </span></div>
                    <div class="mt5"><span class="Ttext">互动话术数量：
                        <span
                          style="color: #fff;">{{ i.interaction_ids_num }}个</span>
                      </span>
                    </div>
                    <div class="mt5"><span class="Ttext">创建时间：<span
                          style="color: #fff;"
                        >{{ i.create_time }}</span></span></div>
                  </div>
                  <div
                    class="tools"
                    @click.stop="delitem(i)"
                  >
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <myempty text="商品讲解"></myempty>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      title="选择商品讲解"
      :visible.sync="showAdd"
      width="70%"
      :show-close="false"
    >
      <!-- 搜索框 -->
      <div class="ipt">
        <el-input
          v-model="search"
          placeholder="输入关键词搜索视频"
          suffix-icon="el-icon-search"
          @blur="handleSelect"
          @keyup.enter.native="handleSelect"
        ></el-input>
      </div>
      <div class="dlistBox">
        <el-checkbox-group v-model="checkList">
          <div class="info">
            <div v-if="evideoList.length > 0">
              <div
                v-for="(i, idx) in evideoList"
                :key="idx"
                class="listBox item"
                @click="activeInfo = i"
              >
                <div class="check mr10">
                  <el-checkbox
                    :key="i.id"
                    :label="i.id"
                  ><br /></el-checkbox>
                </div>
                <img
                  src="~@/assets/img/shop.png"
                  alt=""
                />
                <div class="info">
                  <div class="tit"><span class="Ttext">讲解名称：</span>
                    {{ i.goods_name }}</div>
                  <div class="mt8"><span
                      class="Ttext">讲解视频数量</span>{{ i.videvo_ids_num }}个</div>
                  <div class="mt8"><span
                      class="Ttext">互动话术数量</span>{{ i.interaction_ids_num }}个
                  </div>
                  <div class="mt8"><span
                      class="Ttext">创建时间：</span>{{ i.create_time }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <myempty text="商品讲解"></myempty>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showAdd = false">取 消</el-button>
        <el-button
          type="primary"
          @click="additem"
        >添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { get_live_goods, dellive, del_live_video, goods_get_list, add_live_goods } from "@/api/live.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  props: {
    info: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      activeNames: 'list',
      form: {
        name: "",
        videoName: "",
      },
      id: '',
      activeName: "first",
      showList: true,
      showAdd: false,
      keywordList: ["你好", "在吗"],
      descList: [],
      checkList: [],
      current: 0,
      menu: [
        {
          icon: require("@/assets/img/secondMenu/jiangjie1.png"),
          activeicon: require("@/assets/img/secondMenu/jiangjie.png"),
          title: "商品讲解",
          pageName: "video",
          params: {},
        },
      ],
      search: "",
      visible: false,
      dialogVisible: false,
      ipt: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      activeInfo: {},
      isPlay: false,
      videoList: [],
      evideoList: [],
    };
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getlive();
      this.getexplain();
    },
    back () {
      this.$emit("back");
    },
    handleSelect () {
      console.log(this.ipt);
    },
    getlive () {
      console.log(this.info);
      get_live_goods({
        page: 1,
        item: 1000,
        live_id: this.info.id,
      }).then((res) => {
        if (res.code == "200") {
          this.videoList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getexplain () {
      goods_get_list({
        page: 1,
        item: 1000,
      }).then((res) => {
        if (res.code == "200") {
          this.evideoList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    additem () {
      add_live_goods({
        live_id: this.info.id,
        goods_ids: this.checkList,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.showAdd = false;
          this.getlive();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    delitem (i) {
      del_live_video({
        live_id: this.info.id,
        goods_id: i.id,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getlive();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick (command) {
      this.type = command;
      switch (command) {
        case "edit":
          this.$emit("add", this.activeI.id);
          break;
        case "rename":
          this.title = "修改直播方案名称";
          this.dialogVisible = true;
          this.ipt = this.activeI.title;
          break;
        case "del":
          this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              dellive({
                live_id: this.activeI.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlive();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => { });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__active-bar {
  background-color: #04ddb5;
}
/deep/.el-tabs__item:hover {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-input__inner {
  border: 0;
  color: #ffffff;
  background: #f2f3f5;
}
/deep/.el-tabs__item.is-active {
  color: rgba(4, 221, 181, 1);
}
/deep/.el-tabs__item {
  color: #ffffff;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #ffffff00;
}
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 2px;
  // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 40vw;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }

  img {
    width: 90px;
    height: 130px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.listBox:last-child {
  margin-bottom: 0;
}
/deep/.el-dialog {
  border-radius: 8px;
  background: rgba(45, 50, 64, 1);
}
/deep/.el-dialog__title{
  color: white;
}
.twoMenubox {
  display: flex;
  justify-content: space-between;
  padding-top: 0;

  .menu {
    display: flex;
    align-items: center;
    background: #f2f7fe;
    color: #8b9ab4;
    padding: 10px 8px;
    margin: 8px 10px;
    border-radius: 6px;
    justify-content: space-around;
    width: 130px;
    height: 45px;

    img {
      margin-right: -8px;
      width: 16px;
      height: 18px;
    }
  }

  .active {
    color: #fff !important;
    background: #015ce6;
    margin: 8px 10px;
    border-radius: 6px;
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.vlist {
  width: 99%;
  .info {
    padding-top: 10px;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
    padding: 10px;
    background: #fff;
  }

  /deep/.el-tabs__nav-wrap::after {
    height: 0 !important;
  }
}

.item {
  margin-bottom: 10px;
  background: #151a30;
  border-radius: 5px;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.item .desc {
  padding: 10px;
  text-align: left;
}

.item .tools {
  position: relative;
  z-index: 99;
  width: 27px;
  height: 27px;
  line-height: 27px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #c8dfff;
  top: -5px;
  left: 40%;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 20px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .item {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
  .item:nth-child(6n) {
    margin-right: 15px;
  }
}
</style>
