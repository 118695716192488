<template>
  <div class="container bg">
    <div v-show="show == 'list'">
      <div class="df jc-sb bg-white head">
        <div class="df">
          <div class="dfc mr50">
            <el-input
              v-model="minFormdata.videoName"
              placeholder="请输入直播方案名称"
            ></el-input>
          </div>
          <div class="df">
            <el-button
              plain
              type="primary"
              @click="getlive(false)"
            >搜索</el-button>
            <el-button
              plain
              @click="clear"
            >清理</el-button>
          </div>
        </div>
        <el-button
          type="primary"
          @click="dialogVisible = true"
        >创建方案</el-button>
      </div>

      <div class="f-w box plr20 pr10">
        <div
          v-if="videoList.length > 0"
          class="df f-w"
        >
          <div
            v-for="(item, idx) in videoList"
            :key="idx"
            class="imgBox"
            @click="(activeInfo = item), (show = 'open')"
          >
            <img
              :src="item.logo"
              object-fit="cover"
              alt=""
              style="background-color: #243748;border-radius: 10px;"
            />
            <div class="desc">
              {{ item.title }}
              <div class="time">{{ item.create_time }}</div>
            </div>
            <div
              class="tools"
              @click.stop="activeInfo = item"
            >
              <el-dropdown
                trigger="click"
                placement="bottom"
                @command="handleClick"
              >
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command="open" icon="el-icon-video-camera">开启直播</el-dropdown-item> -->
                  <el-dropdown-item
                    command="explain"
                    icon="el-icon-notebook-2"
                  >商品讲解</el-dropdown-item>
                  <el-dropdown-item
                    command="option"
                    icon="el-icon-setting"
                  >房管配置</el-dropdown-item>
                  <el-dropdown-item
                    command="rename"
                    icon="el-icon-edit-outline"
                  >重命名</el-dropdown-item>
                  <el-dropdown-item
                    command="del"
                    icon="el-icon-delete"
                  >删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div v-else>
          <myempty text="直播方案"></myempty>
        </div>
      </div>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="38%"
      >
        <el-input
          v-model="ipt"
          placeholder="请输入直播方案名称"
        ></el-input>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="dialogVisible = false;ipt = '';">取 消</el-button>
          <el-button
            type="primary"
            @click="addLive"
          >提 交</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-if="show == 'add'">
      <create
        ref="create"
        :info="activeInfo"
        @back="back"
      ></create>
    </div>
    <div v-if="show == 'option'">
      <options
        :info="activeInfo"
        @back="back"
      ></options>
    </div>
    <div v-if="show == 'open'">
      <openlive
        :info="activeInfo"
        @back="back"
      ></openlive>
    </div>
  </div>
</template>
<script>
import { getlive, dellive, editlive, createlive, get_live_video } from "@/api/live.js";
import options from "./option.vue";
import create from "./create.vue";
import openlive from "./openlive.vue";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
    options,
    create,
    openlive,
  },
  data () {
    return {
      minFormdata: {
        videoName: "",
      },
      show: "list",
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      openLive: false,
      showStatus: false,
      disabled: false,
      isopen: false,
      fullscreenLoading: false,
      israndom: false,
      isAddr: false,
      isRoomId: false,
      ipt: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      videoList: [],
    };
  },
  mounted () {
    this.getlive();
  },
  methods: {
    addLive () {
      if (!this.ipt) {
        this.$message.error("请输入直播方案名称");
        return;
      }
      switch (this.type) {
        case "rename":
          editlive({
            live_id: this.activeInfo.id,
            title: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.getlive();
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
        default:
          console.log("add");
          createlive({
            title: this.ipt,
          }).then((res) => {
            console.log(res);
            if (res.code == "200") {
              this.dialogVisible = false;
              this.getlive(true);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
      }
      this.ipt = "";
    },
    edit () {
      this.show = "add";
      // this.$refs.create.id = this.activeInfo.id;
      // this.$refs.create.init();
    },
    back () {
      this.show = "list";
      this.getlive();
    },
    showExplain (i) {
      this.show = "add";
    },
    showOption (i) {
      console.log("showOption");
      this.show = "option";
      console.log(this.show);
    },
    setInfo (i) {
      this.activeInfo = i;
    },
    clear () {
      this.minFormdata = {
        videoName: "",
      };
      this.getlive();
    },
    getlive (toDetail) {
      getlive({
        page: 1,
        item: 1000,
        so: this.minFormdata.videoName || "",
      }).then((res) => {
        if (res.code == "200") {
          this.videoList = res.data.list;
          if (toDetail) {
            this.activeInfo = this.videoList[0];
            this.edit();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick (command) {
      console.log(this.activeInfo);
      this.type = command;
      switch (command) {
        case "open":
          this.show = "open";
          break;
        case "explain":
          // this.$emit("add", this.activeI.id);
          // this.showExplain();
          this.edit();
          break;
        case "option":
          this.showOption();
          break;
        case "rename":
          this.title = "修改直播方案名称";
          this.dialogVisible = true;
          this.ipt = this.activeInfo.title;
          break;
        case "del":
          this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              dellive({
                live_id: this.activeInfo.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlive();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => { });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.live {
  .statusBox {
    padding: 3px;
    border-radius: 5px;
    font-size: 12px;
  }
  .success {
    background: #67c23a;
    color: #fff;
  }

  .error {
    background: #f56c6c;
    color: #fff;
  }

  /deep/.el-dialog__body {
    padding-top: 0px;
  }

  /deep/.el-input__inner {
    border: 0;
    background: hsl(217, 40%, 96%);
  }

  .sel {
    /deep/.el-input__inner {
      width: 26vw;
    }
  }
}

/deep/.el-textarea__inner {
  background: #f1f4f9 !important;
}

/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

/deep/.el-main {
  padding: 15px !important;
}

/deep/.el-dialog {
  background: rgba(45, 50, 64, 1);
  border-radius: 8px;
}
/deep/.el-dialog__title {
  color: white;
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.imgBg {
  img {
    width: 175px;
    height: 275px;
  }
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.voxBox {
  .btns {
    padding: 15px;
    background: #f1f4f9;
    height: 245px;
    overflow: scroll;

    /deep/.el-button {
      padding: 10px !important;
      margin-bottom: 10px;
      margin-left: 0 !important;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      margin-right: 7px;
    }
  }

  .bottom {
    padding: 10px 20px;
  }
}

/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}
</style>
